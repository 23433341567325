/** MAILBOX */

.mailbox {
  display: grid;
  grid:
    "ctrl" auto
    "list" auto
    "mesg" auto / 1fr;
  justify-self: center;
}

.message-list-controls {
  display: none;
  grid-area: ctrl;
}

.list-active .message-list-controls {
  display: flex;
}

.message-list-controls button,
.message-list-controls input {
  border: 1px solid var(--border-color);
  border-radius: 3px;
}

.message-list-controls button {
  color: var(--low-color);
  margin-left: 1px;
  padding: 0 6px;
}

.message-list-controls button:disabled {
  color: var(--disabled-color);
  cursor: default;
}

.message-list-controls input {
  flex: 1 1 auto;
  padding: 2px 4px;
}

.message-list {
  display: none;
  grid-area: list;
}

.list-active .message-list {
  display: block;
}

.message {
  display: none;
  grid-area: mesg;
  min-height: 0;
  min-width: 0;
}

.message-active .message {
  display: block;
}

.message-close {
  margin-right: auto !important;
}

@media screen and (min-width: 1000px) {
  .mailbox {
    grid-gap: 1px 20px;
    grid:
      "ctrl mesg" auto
      "list mesg" 1fr
      / minmax(200px, 300px) minmax(650px, auto);
    height: 100%;
  }

  .message-list {
    display: block;
    overflow-y: auto;
  }

  .message-list-controls {
    display: flex;
  }

  .message {
    display: block;
    overflow: auto;
  }

  .message-close {
    display: none !important;
  }
}

.message-list-entry {
  border-color: var(--border-color);
  border-width: 1px;
  border-style: none solid solid solid;
  cursor: pointer;
  outline: none;
  padding: 5px 8px;
}

.message-list-entry:focus {
  background-color: var(--focused-bg-color) !important;
}

.message-list-entry.selected {
  background-color: var(--selected-color);
}

.message-list-entry:first-child {
  border-style: solid;
}

.message-list-entry:focus .subject {
  color: var(--focused-color);
}

.message-list-entry .subject {
  color: var(--high-color);
}

.message-list-entry.unseen .subject {
  font-weight: bold;
}

.message-list-entry:focus .from,
.message-list-entry:focus .date {
  color: var(--focused-color);
  opacity: 0.8;
}

.message-list-entry .from,
.message-list-entry .date {
  color: var(--low-color);
  font-size: 85%;
}

.message-header {
  border: 1px solid var(--border-color);
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0,0,0,.05);
  padding: 10px;
  margin: 10px 0;
}

.message-header dt {
  color: var(--low-color);
  font-weight: bold;
}

.message-header dd {
  color: var(--low-color);
  padding-left: 10px;
}

@media screen and (min-width: 1000px) {
  .message-header {
    display: grid;
    grid-template: auto / 5em 1fr;
  }

  .message-header dt {
    grid-column: 1;
    text-align: right;
  }

  .message-header dd {
    grid-column: 2;
  }
}

.message-body {
  padding: 5px;
}

.message-warn li {
  margin-left: 20px;
  padding-left: 0px;
}

.message-warn-severe {
  font-weight: 700;
}

nav.tab-bar {
  border-bottom: 1px solid var(--border-color);
  display: flex;
  margin: 20px 0 10px 0;
}

nav.tab-bar a {
  border-radius: 4px 4px 0 0;
  display: block;
  margin-bottom: -1px;
  margin-right: 2px;
  padding: 8px 15px;
  text-decoration: none;
}

nav.tab-bar a.active {
  color: var(--low-color);
  border-color: var(--border-color) var(--border-color) var(--bg-color) var(--border-color);
  border-style: solid;
  border-width: 1px;
}

nav.tab-bar a:focus,
nav.tab-bar a:hover {
  background-color: var(--selected-color);
}

nav.tab-bar a.active:focus,
nav.tab-bar a.active:hover {
  background-color: var(--bg-color);
}

.attachments {
  width: 100%;
}
